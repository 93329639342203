import Button from '@pluralsight/ps-design-system-button';
import { EqualColumnLayout } from '@pluralsight/ps-design-system-layout';
import { Heading, P } from '@pluralsight/ps-design-system-text';
import { CenteredExample, PrincipleHeader, Quote, QuoteList } from "../../../../src/components/voice";
import * as React from 'react';
export default {
  Button,
  EqualColumnLayout,
  Heading,
  P,
  CenteredExample,
  PrincipleHeader,
  Quote,
  QuoteList,
  React
};