import NavBar from '@pluralsight/ps-design-system-navbar';
import NavBrand from '@pluralsight/ps-design-system-navbrand';
import NavItem from '@pluralsight/ps-design-system-navitem';
import NavUser from '@pluralsight/ps-design-system-navuser';
import NavCookieBanner from '@pluralsight/ps-design-system-navcookiebanner';
import * as React from 'react';
export default {
  NavBar,
  NavBrand,
  NavItem,
  NavUser,
  NavCookieBanner,
  React
};