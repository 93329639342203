import Avatar from '@pluralsight/ps-design-system-avatar';
import Card from '@pluralsight/ps-design-system-card';
import Carousel from '@pluralsight/ps-design-system-carousel';
import Note from '@pluralsight/ps-design-system-note';
import { MockItem } from "../../../../src/components/carousel";
import * as React from 'react';
export default {
  Avatar,
  Card,
  Carousel,
  Note,
  MockItem,
  React
};