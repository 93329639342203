// extracted by mini-css-extract-plugin
export var travel = "travel-module--travel--RXHSm";
export var pills = "travel-module--pills--khhc3";
export var pill = "travel-module--pill--yzQFo";
export var pill1 = "travel-module--pill1--08nZq";
export var pill2Container = "travel-module--pill2Container--mn4z5";
export var pill2 = "travel-module--pill2--KFemD";
export var pill3Container = "travel-module--pill3Container--AvBPy";
export var pill3 = "travel-module--pill3--LDUPw";
export var lines = "travel-module--lines--QSBvg";
export var line = "travel-module--line--vpOWa";