// extracted by mini-css-extract-plugin
export var parent = "example-module--parent--vzLEL";
export var example = "example-module--example--KUQup";
export var box = "example-module--box--5kDLR";
export var border = "example-module--border--sjwGS";
export var label = "example-module--label--ly2eW";
export var varName = "example-module--varName--uhGIz";
export var line = "example-module--line--ixuBv";
export var lineSingleSide = "example-module--lineSingleSide--deu2S";
export var lineTop = "example-module--lineTop--BoCWB";
export var lineRight = "example-module--lineRight--UMxEQ";
export var lineBottom = "example-module--lineBottom--MZZIq";
export var lineLeft = "example-module--lineLeft--g5QtN";