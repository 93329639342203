import { FontFamily, FontWeight, FontSize, LineHeight, LetterSpacing, MonospaceFontWeight, MonospaceGlyphs } from "../../../../src/components/typography";
import * as React from 'react';
export default {
  FontFamily,
  FontWeight,
  FontSize,
  LineHeight,
  LetterSpacing,
  MonospaceFontWeight,
  MonospaceGlyphs,
  React
};