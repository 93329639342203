// extracted by mini-css-extract-plugin
export var lineHeights = "line-height-module--lineHeights--WKwr3";
export var lineHeight = "line-height-module--lineHeight--Kh-rf";
export var text = "line-height-module--text--w4vvi";
export var textInner = "line-height-module--textInner--ahPH2";
export var textBg = "line-height-module--textBg--3v4QG";
export var textHuge = "line-height-module--textHuge--SIaWi";
export var textExtra = "line-height-module--textExtra--gaw9r";
export var textStandard = "line-height-module--textStandard--hY71t";
export var textTight = "line-height-module--textTight--moH67";
export var textBgHuge = "line-height-module--textBgHuge--CCRoQ";
export var textBgExtra = "line-height-module--textBgExtra--IaHr5";
export var textBgStandard = "line-height-module--textBgStandard--lwe-U";
export var textBgTight = "line-height-module--textBgTight--DynLi";
export var label = "line-height-module--label--MrFH8";
export var varName = "line-height-module--varName--kyvbA";