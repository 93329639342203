// extracted by mini-css-extract-plugin
export var header = "styles-module--header--l7YGK";
export var title = "styles-module--title--Ttlhy";
export var hackToMakeMoreSpecific = "styles-module--hack-to-make-more-specific--+F+b9";
export var codeBlock = "styles-module--codeBlock--N1d5z";
export var actions = "styles-module--actions--phCzR";
export var actionsLeft = "styles-module--actionsLeft--3aHEi";
export var actionsRight = "styles-module--actionsRight--0LO9+";
export var preview = "styles-module--preview--kZNPk";
export var dark = "styles-module--dark--gG5UR";
export var light = "styles-module--light--LauYX";
export var editor = "styles-module--editor--yxLjk";
export var editorExpanded = "styles-module--editorExpanded--ehPy7";
export var editorFade = "styles-module--editorFade--okbC2";
export var clickToExpand = "styles-module--clickToExpand--RD8p9";
export var example = "styles-module--example---Mo-J";